import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile, isTablet } from "react-device-detect";
import { setSessionSettings, sendEventToGA } from "../../redux/action/index";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      title: "Welcome to Allianz Insight",
      description: "Our Financial Needs Analyzer will help you discover the right solution in achieving your financial goals through Financial Needs Analysis.",
      description2: "We are behind you in finding the right solutions to reach your financial goals!",
      buttonMessage: "LET'S GET STARTED!",
      testImage: ''
    });
    this.nextPage = this.nextPage.bind(this);
  }


  nextPage() {
    sendEventToGA("Landing", "ClickedGetStartedButton", "User pressed the get started button in landing page.", 1);
    this.props.dispatch(setSessionSettings({
      page: 1,
      selectedNeedId: 0,
      selectedNeedTitle: ''
    }), function () {
    });
    window.location.href = '/fna';
  }

  render() {
    const isMobileNotTablet = isMobile && !isTablet;
    return (
      <div className="LandingPage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 text-center">
              <div className={isMobileNotTablet ? "LandingPageDescriptionContainerMobile" : "LandingPageDescriptionContainerDesktop landingPageContainer"}>
                <h1 className={`useAllianzSemiboldFont textPrimary fw400f fs32r`}>{this.state.title}</h1><br />
                <label className={`useAllianzFont textBlack fw400f fs16r mw500`}>{this.state.description}</label><br />
                <label className={`useAllianzFont textBlack fw400f fs16r mw500`}>{this.state.description2}</label><br />
                <button onClick={() => { this.nextPage() }}
                  className={`LandingPageBtn useAllianzFont fs100pct fw800f up`}>{this.state.buttonMessage}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getSessionSettings: state.getSessionSettings
  }
}

export default connect(mapStateToProps)(Landing)
